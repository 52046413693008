var stressaway_app = {};

// Define constants
stressaway_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
stressaway_app.DEFAULT_DURATION = 300;
stressaway_app.HEADER_HEIGHT = 79;

// Function to define header style (default/small)
stressaway_app.toggleHeaderStyle = function() {

    function setSmallHeader() {
        jQuery('body').addClass('header--small');
        jQuery('#navbar-header').addClass('navbar__header--small');
        jQuery('#main-menu').addClass('main-menu--small');
    }

    function unsetSmallHeader() {
        jQuery('body').removeClass('header--small');
        jQuery('#navbar-header').removeClass('navbar__header--small');
        jQuery('#main-menu').removeClass('main-menu--small');
    }

    // On mobile device or if header is locked to small class
    if ((jQuery(window).width() < stressaway_app.BREAKPOINTS.LG ) || (jQuery('#navbar-header').hasClass("navbar__header--small--lock"))) {
        setSmallHeader();
        return;
    }

    if (jQuery(window).scrollTop() > stressaway_app.HEADER_HEIGHT) {
        setSmallHeader();
    } else {
        unsetSmallHeader();
    }
};

// Function to scroll to a page section
stressaway_app.scrollTo = function(selector) {
    jQuery(selector).animatescroll( {
        padding: stressaway_app.HEADER_HEIGHT,
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
};

jQuery(document).ready(function($) {
    // Init interface
    stressaway_app.toggleHeaderStyle();

    if ($(".news-index").length) {
        $(".news-index").imagesLoaded( function() {
            $('.grid').masonry({
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                percentPosition: true,
                horizontalOrder: true
            });
        });
    }


    // Menu button
    $('#btn-menu-open').click(function() {

        $('#main-menu').addClass('main-menu--active');

        var scrollY = $(window).scrollTop();
        $('body').css({
            'position': 'fixed',
            'top': '-' + scrollY + 'px'
        });
    });

    $('#btn-menu-close').click(function() {
        $('#main-menu').removeClass('main-menu--active');

        var scrollY = $('body').css('top');
        $('body').css({
            'position': '',
            'top': ''
        });
        $(window).scrollTop(parseInt(scrollY || '0') * -1);
    });

    // ScrollTo allow to scroll within the solutions page
    $('.scroll-to').removeAttr("href");

    $('.scroll-to').click(function() {
        var scrollLink = $(this).attr('data-link');

        stressaway_app.scrollTo(scrollLink);
    });

});

jQuery(window).scroll(function() {
    //stressaway_app.debouncedToggleHeaderStyle();
    stressaway_app.toggleHeaderStyle();
});

jQuery(window).resize(function() {
    //stressaway_app.debouncedToggleHeaderStyle();
    stressaway_app.toggleHeaderStyle();
});
